/* --------------------------------
   team
-------------------------------- */

.team
{
	&__inner { }

	&__item
	{
		position: relative;

		figure
		{
			position: relative;
			overflow: hidden;
			width: 100%;
			height: 0;
			margin: 0;
			padding-top: percentage(293/297);

			>*
			{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				@include transition( opacity 0.3s ease-in-out );
			}
		}

		img
		{
			background: {
				position: 50% 50%;
				repeat: no-repeat;
				size: cover;
			}
			opacity: .8;
			transform: translateZ(0);
		}

		&--text
		{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			height: 100%;
		}

		&__inner
		{
			background-color: $primary-color;
			@include transition( background-color 0.3s ease-in-out );
		}

		&__descriptiom
		{
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			text-align: left;
			text-decoration: none;
			line-height: 1;
			color: $white;
			padding: 20px;
		}

		&__title
		{
			font: {
				size: rem-calc(20px);
				weight: 600;
				family: $secondary-fontFamily;
			};
		}

		&__subtitle
		{
			font-size: rem-calc(12px);
		}
	}

	&__item--text { padding: 30px 0; }
}