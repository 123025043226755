/* --------------------------------
   fontello
-------------------------------- */

@font-face
{
	font-family: 'fontello';
	src: url('../fonts/fontello.eot?84940194');
	src: url('../fonts/fontello.eot?84940194#iefix') format('embedded-opentype'),
		 url('../fonts/fontello.woff2?84940194') format('woff2'),
		 url('../fonts/fontello.woff?84940194') format('woff'),
		 url('../fonts/fontello.ttf?84940194') format('truetype'),
		 url('../fonts/fontello.svg?84940194#fontello') format('svg');
	font-weight: normal;
	font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?84940194#fontello') format('svg');
  }
}
*/

[class^="fontello-"]:before,
[class*=" fontello-"]:before
{
	font-family: "fontello";
	font-style: normal;
	font-weight: normal;
	speak: none;

	display: inline-block;
	text-decoration: inherit;
	width: auto;
	text-align: center;

	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;

	/* fix buttons height, for twitter bootstrap */
	line-height: 1em;

	/* Font smoothing. That was taken from TWBS */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}