/* --------------------------------
   top bar
-------------------------------- */

#top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 70px;
  background-color: $white;
  z-index: 6;
  @include transition(padding 0.3s ease-in-out);

  &.fixed {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  &.expanded {
    &,
    > .container {
      height: 100%;
    }
  }

  .container {
    @include clearfix();
  }

  &__logo {
    float: left;
    width: 88px;
    height: 49px;
    background-image: url(../img/logo_2.png);
    background-size: 100%;
  }

  &__navigation-toggler {
    position: absolute;
    top: 4px;
    right: 15px;
    padding: 19px 10px;

    span {
      position: relative;
      display: block;
      height: 3px;
      width: 20px;

      &:before,
      &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &:before {
        top: -6px;
      }
      &:after {
        top: 6px;
      }
    }

    span,
    span:before,
    span:after {
      background-color: #555;

      @include transition(all 400ms ease-in-out);
    }

    &.active {
      span {
        background-color: transparent;

        &:before,
        &:after {
          top: 0;
          transform-origin: 50% 50%;
        }

        &:before {
          transform: rotate(225deg);
        }

        &:after {
          transform: rotate(-225deg);
        }
      }
    }
  }

  &__navigation {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    overflow-y: auto;

    &:before,
    > ul {
      display: inline-block;
      vertical-align: middle;
    }

    &:before {
      content: "";
      width: 0;
      height: 100%;
      margin-left: -4px;
    }

    > ul {
    }

    ul {
      line-height: 0;
      font-size: 0;
      letter-spacing: -1px;
      white-space: nowrap;
      text-align: left;
      @include clearfix();
    }

    li {
      position: relative;
      outline: none;
      margin-top: 15px;

      &:not(.li-btn) {
        &.active,
        &.current,
        &:hover {
          > a {
            color: $secondary-color;

            &:after {
              width: 16px;
              opacity: 1;
            }
          }
        }
      }
    }

    a {
      position: relative;
      text-decoration: none;

      &:not(.custom-btn) {
        display: block;
        line-height: 1;
        letter-spacing: 0;
        white-space: normal;
        font-size: rem-calc(12px);
        font-weight: 600;
        font-family: $secondary-fontFamily;
        color: #333;
        text-transform: uppercase;
        outline: none;
        padding: 3px 5px;

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 5px;
          width: 0;
          height: 2px;
          opacity: 0;
          background-color: $secondary-color;

          @include transition(width 0.2s ease-in-out, opacity 0.2s ease-in-out);
        }
      }
    }

    .submenu {
      display: none;

      ul {
        margin-left: 10px;
      }
    }
  }
}

.top-bar--style-1 {
}

.top-bar--style-2 {
}

.top-bar--style-3 {
  #top-bar__navigation {
    li {
      &:not(.li-btn) {
        &.active,
        &.current,
        &:hover {
          > a {
            color: $primary-color;
          }
        }
      }
    }

    a {
      &:not(.custom-btn) {
        &:after {
          background-color: $primary-color;
        }
      }
    }
  }
}

@include min-screen($md-width) {
  #top-bar {
    position: absolute;
    padding-top: 20px;
    padding-bottom: 20px;

    &.fixed {
      position: fixed !important;
      top: 0 !important;
      padding-top: 15px;
      padding-bottom: 15px;
      min-height: 80px;
      background-color: $white !important;

      .desktop & {
        animation-duration: 0.3s;
        animation-fill-mode: both;
      }

      .desktop &.in {
        -webkit-animation-name: TopBarSlideInDown;
        animation-name: TopBarSlideInDown;
      }

      .desktop &.out {
        -webkit-animation-name: TopBarSlideOutUp;
        animation-name: TopBarSlideOutUp;
      }

      #top-bar__navigation {
        padding-top: 20px;
      }
    }

    &__navigation {
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
      padding-top: 20px;
      padding-left: 30px;
      margin-left: 85px;
      text-align: left;
      overflow-y: visible;
      display: block;

      &:before {
        content: none;
      }

      > ul {
        display: block;
      }

      li {
        display: inline-block;
        vertical-align: middle;
        margin-top: 0;
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }

        &.li-btn {
          float: right;
          margin-top: -18px;
        }

        &:hover {
          > .submenu {
            padding-top: 10px;
            visibility: visible;
            opacity: 1;
          }
        }
      }

      .submenu {
        display: block;
        position: absolute;
        top: 100%;
        left: 5px;
        width: 200px;
        padding-top: 30px;
        visibility: hidden;
        opacity: 0;
        z-index: 3;
        @include transition(
          opacity 0.2s ease-in-out,
          margin-left 0.2s ease-in-out,
          margin-right 0.2s ease-in-out,
          padding-top 0.2s ease-in-out,
          visibility 0.2s ease-in-out
        );

        ul {
          background-color: #282828;
          margin-left: 0;
          padding: 30px;
        }

        li {
          display: block;
          margin-top: 20px;
          margin-left: 0;

          &:first-child {
            margin-top: 0;
          }
        }

        a {
          color: $white;
        }
      }
    }

    &__navigation-toggler {
      display: none;
    }
  }

  .top-bar--style-1 {
    &:not(.fixed) {
      top: 20px !important;
      background-color: transparent !important;

      #top-bar__logo {
        background-image: url(../img/logo_1.png);
      }

      #top-bar__navigation {
        > ul > li > a:not(:hover):not(:focus) {
          color: $white;
        }
      }
    }
  }

  .top-bar--style-2 {
  }

  .top-bar--style-3 {
    &:not(.fixed) {
      top: 15px !important;
      background-color: transparent !important;

      .container {
        &:before {
          content: "";
          position: absolute;
          top: -20px;
          right: -5px;
          bottom: -20px;
          left: -5px;
          background-color: $white;
        }
      }
    }
  }
}

@include min-screen($lg-width) {
  #top-bar {
    &__navigation {
      padding-left: 60px;
    }
  }
}

@include min-screen($xl-width) {
  #top-bar {
    &__navigation {
      padding-left: 90px;
    }
  }
}

@-webkit-keyframes TopBarSlideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes TopBarSlideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes TopBarSlideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes TopBarSlideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
