/* --------------------------------
   timeline
-------------------------------- */

.timeline
{
	border-left: 1px solid #c8cfb8;

	&__inner { margin-bottom: -30px; }

	&__item
	{
		position: relative;
		margin-bottom: 30px;
		padding-left: 40px;

		&:before,
		&:after
		{
			content: "";
			position: absolute;
			background-color: #c8cfb8;
		}

		&:before
		{
			top: 25px;
			left: 0px;
			width: 30px;
			height: 1px;
		}

		&:after
		{
			top: 19px;
			left: -7px;
			width: 14px;
			height: 14px;
			border: 4px solid #fff;
			@include border-radius(50%);
		}
	}

	&__year,
	&__title
	{
		color: $primary-color;
		font-weight: 700;
	}

	&__year
	{
		line-height: 1;
		font-size: rem-calc(60px);
		font-family: $secondary-fontFamily;
	}

	&__title
	{
		line-height: 1.3;
		font-size: rem-calc(18px);
	}
}

@include min-screen($sm-width)
{
	
}

@include min-screen($md-width)
{
	.timeline
	{
		border-top: 1px solid #c8cfb8;
		border-left: none;

		&__item
		{
			padding-left: 0;
			padding-top: 55px;

			&:before
			{
				top: 0;
				left: 6px;
				width: 1px;
				height: 40px;
			}

			&:after
			{
				top: -7px;
				left: 0;
			}
		}
	}
}

@include min-screen($lg-width)
{
	
}

@include min-screen($xl-width)
{
	
}