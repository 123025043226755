.fontello-user:before { content: '\e800'; } /* '' */
.fontello-mail:before { content: '\e801'; } /* '' */
.fontello-comment:before { content: '\e802'; } /* '' */
.fontello-location:before { content: '\e803'; } /* '' */
.fontello-mobile:before { content: '\e804'; } /* '' */
.fontello-phone-call:before { content: '\e805'; } /* '' */
.fontello-down:before { content: '\e806'; } /* '' */
.fontello-ok-1:before { content: '\e807'; } /* '' */
.fontello-left:before { content: '\e808'; } /* '' */
.fontello-right:before { content: '\e809'; } /* '' */
.fontello-up:before { content: '\e80a'; } /* '' */
.fontello-cancel:before { content: '\e80b'; } /* '' */
.fontello-down-open:before { content: '\f004'; } /* '' */
.fontello-up-open:before { content: '\f005'; } /* '' */
.fontello-right-open:before { content: '\f006'; } /* '' */
.fontello-left-open:before { content: '\f007'; } /* '' */
.fontello-reply:before { content: '\f02a'; } /* '' */
.fontello-twitter:before { content: '\f099'; } /* '' */
.fontello-facebook:before { content: '\f09a'; } /* '' */
.fontello-pinterest-circled:before { content: '\f0d2'; } /* '' */
.fontello-pinterest-squared:before { content: '\f0d3'; } /* '' */
.fontello-gplus-squared:before { content: '\f0d4'; } /* '' */
.fontello-gplus:before { content: '\f0d5'; } /* '' */
.fontello-linkedin:before { content: '\f0e1'; } /* '' */
.fontello-youtube:before { content: '\f167'; } /* '' */
.fontello-youtube-play:before { content: '\f16a'; } /* '' */
.fontello-dropbox:before { content: '\f16b'; } /* '' */
.fontello-instagram:before { content: '\f16d'; } /* '' */
.fontello-windows:before { content: '\f17a'; } /* '' */
.fontello-skype:before { content: '\f17e'; } /* '' */
.fontello-vkontakte:before { content: '\f189'; } /* '' */
.fontello-vimeo-squared:before { content: '\f194'; } /* '' */
.fontello-slack:before { content: '\f198'; } /* '' */
.fontello-google:before { content: '\f1a0'; } /* '' */
.fontello-pied-piper-squared:before { content: '\f1a7'; } /* '' */
.fontello-git-squared:before { content: '\f1d2'; } /* '' */
.fontello-git:before { content: '\f1d3'; } /* '' */
.fontello-paypal:before { content: '\f1ed'; } /* '' */
.fontello-pinterest:before { content: '\f231'; } /* '' */
.fontello-whatsapp:before { content: '\f232'; } /* '' */
.fontello-odnoklassniki:before { content: '\f263'; } /* '' */
.fontello-odnoklassniki-square:before { content: '\f264'; } /* '' */
.fontello-chrome:before { content: '\f268'; } /* '' */
.fontello-firefox:before { content: '\f269'; } /* '' */
.fontello-opera:before { content: '\f26a'; } /* '' */
.fontello-internet-explorer:before { content: '\f26b'; } /* '' */
.fontello-amazon:before { content: '\f270'; } /* '' */
.fontello-vimeo:before { content: '\f27d'; } /* '' */
.fontello-twitter-squared:before { content: '\f304'; } /* '' */
.fontello-facebook-squared:before { content: '\f308'; } /* '' */
.fontello-linkedin-squared:before { content: '\f30c'; } /* '' */