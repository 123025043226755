/* --------------------------------
   Page ontact
-------------------------------- */

.page-landing
{
	.section-services
	{
		background-color: #37343b;
		color: $white;

		h2 { color: inherit; }

		h4 { color: #a6a6a6; }
	}

	.section-about
	{
		color: $white;

		&__title,
		h4,
		.custom-btn:not(:hover):not(:focus)
		{
			color: inherit;
		}

		&__title { font-size: rem-calc(40px); }
	}

	.section-banner
	{
		&__title
		{
			line-height: 1;
			font-size: rem-calc(60px);
		}
	}

	.section-gallery
	{
		.item
		{
			padding-top: 55px;
			padding-bottom: 55px;
			@include transition( padding 0.3s ease-in-out );

			@extend %bg-cover;

			&__title
			{
				line-height: 1.2;
				font-size: rem-calc(50px);
				color: $white;
			}

			&:hover
			{
				padding-top: 55px+20px;
				padding-bottom: 55px+20px;
			}
		}
	}
}

@include min-screen($sm-width)
{
	.page-landing
	{
		.section-banner
		{
			&__title { font-size: rem-calc(80px); }
		}
	}
}

@include min-screen($md-width)
{
	.page-landing
	{
		.section-about
		{
			&__title { font-size: rem-calc(60px); }
		}

		.section-banner
		{
			&__title { font-size: rem-calc(100px); }
		}

		.section-gallery
		{
			.item
			{
				padding-top: 75px;
				padding-bottom: 75px;

				&:hover
				{
					padding-top: 75px+30px;
					padding-bottom: 75px+30px;
				}
			}
		}
	}
}

@include min-screen($lg-width)
{
	.page-landing
	{
		.section-gallery
		{
			.item
			{
				padding-top: 95px;
				padding-bottom: 95px;

				&:hover
				{
					padding-top: 95px+30px;
					padding-bottom: 95px+30px;
				}
			}
		}
	}
}

@include min-screen($xl-width)
{
	
}