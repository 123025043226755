/* --------------------------------
   custom owl carousel styles
-------------------------------- */

.owl-theme
{
	.owl-dots
	{
		line-height: 1;
		font-size: 0;
		margin-top: 20px !important;

		.owl-dot
		{
			vertical-align: top;
			outline: none;

			span
			{
				width: 13px;
				height: 13px;
				background-color: $secondary-color;
				box-shadow: 0 0 0 0 $primary-color inset;
				margin: 5px 4px;
				@include border-radius(50%);
				@include transition(
					background 0.3s ease-in-out,
					box-shadow 0.3s ease-in-out,
					opacity 0.3s ease-in-out
				);

				[style="background-color: #f1cf69;"] & { background-color: #ceb568; }
			}

			&.active,
			&:hover
			{
				span
				{
					box-shadow: 0 0 0 8px $primary-color inset;
				}
			}
		}
	}
}