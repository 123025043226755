/* --------------------------------
   Page ontact
-------------------------------- */

.page-contacts
{
	main { border-bottom: 1px solid #e9e9e9; }

	.contact-address
	{
		margin-top: 50px;
		margin-bottom: 50px;

		&:first-child { margin-top: 0; }
		&:last-child { margin-bottom: 0; }

		&__inner
		{
			margin-bottom: -50px;
			overflow: hidden;
		}
	}

	.contact__item
	{
		position: relative;
		margin-bottom: 50px;
		font-family: $secondary-fontFamily;
		font-weight: 400;
		text-align: center;

		&__ico
		{
			display: inline-block;
			vertical-align: top;
			line-height: 1;
			font-size: rem-calc(45px);
			color: $primary-color;

			&:before {vertical-align: middle; }
		}

		&__title
		{
			font-size: 20px;
			font-weight: 600;
			text-transform: uppercase;
		}

		a
		{
			color: inherit;

			&:hover,
			&:focus
			{
				text-decoration: underline;
			}
		}
	}
}

@include min-screen($sm-width)
{
	
}

@include min-screen($md-width)
{
	.page-contacts
	{
		.contact__item
		{
			&__ico { margin-bottom: 15px; }
		}
	}
}

@include min-screen($lg-width)
{
	
}

@include min-screen($xl-width)
{
	
}