* {
  padding: 0;
  margin: 0;

  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

body {
  @extend %pos-relative;
}

ul,
ol {
  list-style: none;
  list-style-image: none;
}

iframe {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

address {
  font-style: normal;
  line-height: inherit;
}

img {
  vertical-align: middle;
  -webkit-user-drag: none;
  user-drag: none;
  @include userSelect(none);
}

/* parallax */
.jarallax {
  position: relative;
  z-index: 0;

  > .jarallax-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include object-fit(cover);
    z-index: -1;
  }

  .desktop & {
    background-attachment: fixed;
  }
}

.site-logo {
  @extend %pos-relative;
  @extend %display-block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

/* float */
.fl-l {
  @extend %fl-l;
}
.fl-r {
  @extend %fl-r;
}

/* text align */
.text-center {
  @extend %text-center;
}
.text-left {
  @extend %text-left;
}
.text-right {
  @extend %text-right;
}

/* vertical align */
.v-top {
  @extend %v-top;
}
.v-middle {
  @extend %v-middle;
}
.v-bottom {
  @extend %v-bottom;
}

.v-align {
  @extend %height-100;

  &:before {
    content: "";
    @extend %width-0;
    @extend %height-100;
    margin-left: -4px;
  }

  &:before,
  > * {
    @extend %display-inline-block;
    @extend %v-middle;
  }

  > * {
    @extend %width-100;
  }
}

.b-table {
  @extend %display-table;
  @extend %width-100;

  .cell {
    @extend %display-table-cell;
  }
}

/* circled element */

.circled {
  @include border-radius(50%);
}

/* column margin bottom */

.col-MB-30 {
  margin-bottom: 30px;
}
.col-MB-25 {
  margin-bottom: 25px;
}
.col-MB-20 {
  margin-bottom: 20px;
}
.col-MB-15 {
  margin-bottom: 15px;
}

@include min-screen($sm-width) {
  .col-sm-MB-30 {
    margin-bottom: 30px;
  }
  .col-sm-MB-25 {
    margin-bottom: 25px;
  }
  .col-sm-MB-20 {
    margin-bottom: 20px;
  }
  .col-sm-MB-15 {
    margin-bottom: 15px;
  }
}

@include min-screen($md-width) {
  .col-md-MB-30 {
    margin-bottom: 30px;
  }
  .col-md-MB-25 {
    margin-bottom: 25px;
  }
  .col-md-MB-20 {
    margin-bottom: 20px;
  }
  .col-md-MB-15 {
    margin-bottom: 15px;
  }
}

@include min-screen($lg-width) {
  .col-lg-MB-30 {
    margin-bottom: 30px;
  }
  .col-lg-MB-25 {
    margin-bottom: 25px;
  }
  .col-lg-MB-20 {
    margin-bottom: 20px;
  }
  .col-lg-MB-15 {
    margin-bottom: 15px;
  }
}

@include min-screen($xl-width) {
  .col-xl-MB-30 {
    margin-bottom: 30px;
  }
  .col-xl-MB-25 {
    margin-bottom: 25px;
  }
  .col-xl-MB-20 {
    margin-bottom: 20px;
  }
  .col-xl-MB-15 {
    margin-bottom: 15px;
  }
}

/* section */

.section {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  z-index: 0;

  &--no-pt {
    padding-top: 0 !important;
  }
  &--no-pb {
    padding-bottom: 0 !important;
  }

  &--screen {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    min-height: calc(100vh - 70px);
    height: auto;
  }

  &--background {
    @extend %bg-cover;
  }
  &--background-logo {
    background: url(../img/img_1.png) no-repeat center right 40px;
  }

  &--background-base {
    background-color: $primary-color;
  }
  &--background-base-light {
    background-color: $primary-color-light;
  }

  &--with-border {
    border-top: 1px solid #e9e9e9;
    padding-top: 59px;
  }

  @include min-screen($md-width) {
    padding-top: 80px;
    padding-bottom: 80px;

    &--screen {
      min-height: calc(100vh - 80px);
    }

    &--with-border {
      padding-top: 79px;
    }
  }

  @include min-screen($lg-width) {
    padding-top: 100px;
    padding-bottom: 100px;

    &--with-border {
      padding-top: 99px;
    }
  }
}

.section--custom-1 {
  @include min-screen($md-width) {
    background: url(../img/bg_02.png) no-repeat right bottom 30%;
    background-size: 80%;
  }

  @include min-screen($xl-width) {
    background-size: auto;
  }
}

.section--custom-2 {
  .img-place {
    @extend %bg-cover;
  }

  @include max-screen($lg-width - 1) {
    background-image: none;
  }

  @include min-screen($lg-width) {
    .img-place {
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      right: 0;
      margin-left: 15px;
    }
  }
}

.section--custom-3 {
  @include min-screen($md-width) {
    background: url(../img/backgroundHD2.png) no-repeat left bottom 110%;
    background-size: 80%;
  }

  @include min-screen($xl-width) {
    background-size: auto;
  }
}
/* embed responsive */

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;

  &-item,
  iframe,
  embed,
  object,
  video {
    @extend %block-absolute--full;
    border: 0;
  }

  &-21by9 {
    padding-bottom: percentage(9 / 21);
  }
  &-16by9 {
    padding-bottom: percentage(9 / 16);
  }
  &-4by3 {
    padding-bottom: percentage(3 / 4);
  }
  &-1by1 {
    padding-bottom: percentage(1 / 1);
  }
}

/* pattern */

.pattern {
  @extend %block-absolute--full;
  background-color: $primary-color;
}

/* google map */

.map-container {
  position: relative;
}

.g_map {
  min-height: 385px;
  height: 100%;
}

/* company-contacts */

.company-contacts {
  address,
  .social-btns {
    margin-top: 35px;
    margin-bottom: 35px;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  address {
    font-size: rem-calc(14px);
    font-family: $secondary-fontFamily;

    p {
      padding-left: 35px;
      margin-top: 25px;
      margin-bottom: 25px;
    }

    i {
      float: left;
      margin-left: -35px;
      line-height: 1.2;
      font-size: rem-calc(17px);
    }

    a:not([class]) {
      color: inherit;
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}

/* social buttons */

.social-btns {
  font-size: 0;
  line-height: 0;
  letter-spacing: -1px;
  color: $white;

  &__inner {
    margin: -5px;
  }

  a {
    @extend %display-inline-block;
    @extend %v-bottom;
    min-width: 1em;
    margin: 5px;
    line-height: 1;
    font-size: rem-calc(20px);
    letter-spacing: 0;
    text-decoration: none;
    @extend %text-center;
    @include transition(
      background-color 0.3s ease-in-out,
      color 0.3s ease-in-out
    );

    &,
    &:hover,
    &:focus {
      color: inherit;
    }
  }
}

/* top button */

#btn-to-top-wrap {
  display: none;
  position: fixed;
  right: 25px;
  bottom: 25px;
  z-index: 5;
}

#btn-to-top {
  position: relative;
  display: block;
  width: 44px;
  height: 44px;
  background-color: darken($secondary-color, 5%);
  line-height: 38px;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  color: $white;
  opacity: 0.7;
  @include transition(opacity 0.3s ease-in-out);

  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    border-bottom: 6px solid $white;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
  }

  &:hover {
    opacity: 1;
  }
}

.ancor {
  display: block;
}

.yellow-title {
  color: yellow;
  text-transform: initial !important;
}
.dark-text {
  color: #333;
}
.bg-white {
  background-color: white;
}
