.h1,
h1,
.h2,
h2,
.h3,
h3 {
  line-height: 1.1;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4 {
  font: {
    weight: 700;
    family: $secondary-fontFamily;
  }
  color: #333;
  @include transition(color 0.3s ease-in-out);

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.h1,
h1 {
  font-size: rem-calc(45px);
}
.h2,
h2 {
  font-size: rem-calc(35px);
}
.h3,
h3 {
  font-size: rem-calc(20px);
}
.h4,
h4 {
  font-size: rem-calc(16px);
}

.h1,
h1 {
  margin-top: 25px;
  margin-bottom: 25px;
}

h1 {
  &.h2,
  &.h3 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &.h4 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.h2,
h2,
.h3,
h3 {
  margin-top: 20px;
  margin-bottom: 20px;
}

h2,
h3 {
  &.h4 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.h4,
h4 {
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 15px;
}

h4 {
  &.h2,
  &.h3 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

a {
  color: $primary-color;
  outline: none;
  @include transition(color 0.3s ease-in-out);

  &:hover,
  &:focus {
    color: $primary-color;
    text-decoration: none;
    outline: none;
  }
}

.section-heading {
  position: relative;
  line-height: 1.4;
  font: {
    size: rem-calc(16px);
    family: $secondary-fontFamily;
    weight: 400;
  }
  color: #a7a7a7;
  width: 100%;
  max-width: 600px;
  padding-top: 97px;
  margin-bottom: 55px;
  z-index: auto;

  &:first-child {
    padding-top: 0;
    margin-top: -3px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.section-heading--white {
    color: $white;

    .title {
      color: inherit;
    }
  }

  &.section-heading--left {
    text-align: left;
    margin-left: 0;
    margin-right: auto;
  }

  &.section-heading--center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  &.section-heading--right {
    text-align: right;
    margin-left: auto;
    margin-right: 0;
  }

  p {
    margin-top: 25px;
  }
}

main {
  p {
    margin-top: 15px;
    margin-bottom: 15px;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
